import React, {Component} from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import { Link } from "gatsby"

import {Helmet} from "react-helmet"


export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>CueForGood: Magento Development, Website Design, Search Engine Optimization and Conversion Rate Optimization for eCommerce Stores | Digital Design and Marketing Agency</title>
          <meta name="description"
                content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
              <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
                <link rel="canonical" href={this.state.url}/>
                <meta property="og:site_name" content="CueForGood"/>
                <meta property="fb:app_id" content="289086684439915"/>
                <meta property="og:url" content={this.state.url}/>
                <meta property="og:title"
                      content="CueForGood: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
                <meta property="og:description"
                      content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites."/>
                <meta property="og:image" content="https://www.CueForGood.com/images/cb.jpg"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:description"
                      content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites."/>
                <meta name="twitter:title"
                      content="CueForGood: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
                <meta name="twitter:site" content="@CueForGood"/>
                <meta name="twitter:image" content="https://www.CueForGood.com/images/cb.jpg"/>
        </Helmet>
        <section className="ser_sec_row privacy-page sitemap-page ">
          <div className="container">
            <div className="main-heading">
              <h2 className="heading_main">SITEMAP</h2>

              <ul className="outerul">
                <li>
                  <h4>  <Link to="/" >Home</Link> </h4></li>
                <li><h4> <Link to="/about" >About</Link> </h4>
                  <ul>
                    <li><Link to="/team" >Team</Link> </li>
                    <li><Link to="/career" >Careers</Link> </li>
                  </ul>
                
                </li>
                <li><h4> <Link to="/cueblocks-for-good" >Cue For Good</Link> </h4></li>
                <li>
                  <h4> <Link to="/services" >Services</Link> </h4>
                      <ul>
                      <li><Link to="/magento" >Magento</Link> </li>
                     <li><Link to="/organic-search" >Organic Search</Link> </li>
                     <li><Link to="/shopify" >Shopify</Link> </li>
                     <li><Link to="/paid-search/" >Digital Advertising</Link> </li>
                    <li><Link to="/wordpress" >WordPress</Link> </li>
                     <li><Link to="/content" >Content Creation</Link> </li>
                      <li><Link to="/php" >PHP</Link> </li>
                    <li><Link to="/email-marketing" >Email Marketing</Link> </li>
                    <li><Link to="/creative-design" >Creative Design</Link> </li>
                    <li><Link to="/magento-1-to-shopify-migration-service" >Magento to Shopify Migration</Link> </li>
                      </ul>
                </li>
                <li><h4><Link to="/work" >Work</Link></h4></li>
                <li><h4> <Link to="/case-studies" >Case Studies</Link> </h4></li>
                <li>
                  <h4><a href="https://www.cueforgood.com/blog/" target="_blank">Blog</a></h4></li>
                <li>
                  <h4><Link to="/contact" >Contact</Link></h4></li>
              </ul>

            </div>

          </div>
        </section>
        <Clutch />
      </Layout>
    )

  }
}

